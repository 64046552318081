@import "./assets/Fonts/Poppins/poppins.scss";
@import "./scrollbar.scss";
@import "/src/responsive";


html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-size: calc((1vh + 1vw ) * 0.62);
  //scroll-behavior: smooth;

  @media only screen and (max-width: $responsiveBreakPoint) {
    font-size: 11.5px;
  }

}

* {
  font-family: $poppinsFontFamily;
}

@font-face {
  font-family: Butler;
  src: url("/assets/Fonts/Butler/Butler_Regular.otf") format("opentype");
  font-weight: normal;
}
@font-face {
  font-family: Butler;
  src: url("/assets/Fonts/Butler/Butler_Bold.otf") format("opentype");
  font-weight: bold;
}

@font-face {
  font-family: Karu;
  src: url("/assets/Fonts/Karu/KaruLight.otf") format("truetype");
  font-weight: light;
}
