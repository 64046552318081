@font-face {
    font-family: Poppins;
    src: url("/assets/Fonts/Poppins/Poppins-Regular.ttf") format("truetype");
    font-weight: 400;
}

@font-face {
    font-family: Poppins;
    src: url("/assets/Fonts/Poppins/Poppins-Light.ttf") format("truetype");
    font-weight: 300;
}

@font-face {
    font-family: Poppins;
    src: url("/assets/Fonts/Poppins/Poppins-SemiBold.ttf") format("truetype");
    font-weight: 600;
}

@font-face {
    font-family: Poppins;
    src: url("/assets/Fonts/Poppins/Poppins-Bold.ttf") format("truetype");
    font-weight: bold;
}

@font-face {
    font-family: Poppins;
    src: url("/assets/Fonts/Poppins/Poppins-ExtraBold.ttf") format("truetype");
    font-weight: 800;
}

@font-face {
    font-family: Poppins;
    src: url("/assets/Fonts/Poppins/Poppins-Medium.ttf") format("truetype");
    font-weight: medium;
}


$poppinsFontFamily: "Poppins", sans-serif;
